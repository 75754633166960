<template>
    <div class="gst-container-with-overlay overflow-hidden u-width-100 u-height-100">
        <slot>
        </slot>
        <v-overlay v-if="overlay"
            :absolute="absolute"
            :color="color"
            :opacity="opacity"
            :overlay="overlay">
            <slot name="overlay"></slot>
        </v-overlay>
    </div>
</template>

<script>
    export default {
        name: 'ContainerWithOverlay',
        props: {
            absolute: {
                type: Boolean,
                default: false
            },
            color: {
                type: String,
                default: '#000000'
            },
            opacity: {
                type: [ String, Number ],
                default: '0.8'
            },
            overlay: {
                type: Boolean,
                default: false
            }
        }
    };
</script>