<template>
    <div
        class="gst-image-masonry-variant-1"
        :class="{
            'u-mouse-pointer': canOpenFullScreen
        }"
        tabindex="0"
        @click="openFullScreenGallery"
        @keypress.enter="openFullScreenGallery">
        <BaseImage v-if="layout0" />
        <div v-if="layout1" class="gst-image-masonry-variant-1_layout_one">
            <ContainerWithOverlay
                :absolute="true"
                :overlay="remainingImagesNumber > 0">
                <BaseImage
                    :src="imagesToDisplay[0].src"
                    :fallback-src="imagesToDisplay[0].fallbackSrc"
                    :cover="true"
                    @error-load="onImageErrorLoadDo( imagesToDisplay[0].src )" />
                <template v-if="remainingImagesNumber" slot="overlay">
                    <p class="gst-remaining-images-number d-flex u-width-100 u-height-100">
                        {{ remainingImages }}
                    </p>
                </template>
            </ContainerWithOverlay>
        </div>

        <div v-if="layout2" class="gst-image-masonry-variant-1_layout_two">
            <div v-for="(image, index) in imagesToDisplay" :key="index" class="gst-image-masonry-variant-1__image">
                <ContainerWithOverlay
                    :absolute="true"
                    :overlay="index === imagesToDisplay.length - 1 && remainingImagesNumber > 0">
                    <BaseImage
                        class="u-height-100"
                        :src="imagesToDisplay[index].src"
                        :fallback-src="imagesToDisplay[index].fallbackSrc"
                        :cover="true"
                        @error-load="onImageErrorLoadDo( imagesToDisplay[index].src )" />
                    <template v-if="index === imagesToDisplay.length - 1" slot="overlay">
                        <p class="gst-remaining-images-number d-flex u-width-100 u-height-100">
                            {{ remainingImages }}
                        </p>
                    </template>
                </ContainerWithOverlay>
            </div>
        </div>

        <div v-if="layout3" class="gst-image-masonry-variant-1_layout_three">
            <BaseImage
                :src="imagesToDisplay[0].src"
                :fallback-src="imagesToDisplay[0].fallbackSrc"
                :cover="true"
                class="gst-image-masonry-variant-1__image"
                @error-load="onImageErrorLoadDo( imagesToDisplay[0].src )" />
            <div class="gst-image-masonry-variant-1_layout_three__right">
                <BaseImage
                    :src="imagesToDisplay[1].src"
                    :fallback-src="imagesToDisplay[1].fallbackSrc"
                    :cover="true"
                    class="gst-image-masonry-variant-1__image"
                    @error-load="onImageErrorLoadDo( imagesToDisplay[1].src )" />
                <div class="gst-image-masonry-variant-1__image">
                    <ContainerWithOverlay
                        :absolute="true"
                        :overlay="remainingImagesNumber > 0">
                        <BaseImage
                            :src="imagesToDisplay[2].src"
                            :fallback-src="imagesToDisplay[2].fallbackSrc"
                            :cover="true"
                            class="u-height-100"
                            @error-load="onImageErrorLoadDo( imagesToDisplay[2].src )" />
                        <template v-if="remainingImagesNumber" slot="overlay">
                            <p class="gst-remaining-images-number d-flex u-width-100 u-height-100">
                                {{ remainingImages }}
                            </p>
                        </template>
                    </ContainerWithOverlay>
                </div>
            </div>
        </div>

        <div v-if="layout4" class="gst-image-masonry-variant-1_layout_four">
            <BaseImage
                :src="imagesToDisplay[0].src"
                :fallback-src="imagesToDisplay[0].fallbackSrc"
                :cover="true"
                class="gst-image-masonry-variant-1__image"
                @error-load="onImageErrorLoadDo( imagesToDisplay[0].src )" />
            <div class="gst-image-masonry-variant-1_layout_four__right">
                <div class="gst-image-masonry-variant-1_layout_four__top-right">
                    <BaseImage
                        :src="imagesToDisplay[1].src"
                        :fallback-src="imagesToDisplay[1].fallbackSrc"
                        :cover="true"
                        class="gst-image-masonry-variant-1__image"
                        @error-load="onImageErrorLoadDo( imagesToDisplay[1].src )" />
                    <BaseImage
                        :src="imagesToDisplay[2].src"
                        :fallback-src="imagesToDisplay[2].fallbackSrc"
                        :cover="true"
                        class="gst-image-masonry-variant-1__image"
                        @error-load="onImageErrorLoadDo( imagesToDisplay[2].src )" />
                </div>
                <div class="gst-image-masonry-variant-1_layout_four__bottom-right">
                    <ContainerWithOverlay
                        :absolute="true"
                        :overlay="remainingImagesNumber > 0">
                        <BaseImage
                            :src="imagesToDisplay[3].src"
                            :fallback-src="imagesToDisplay[3].fallbackSrc"
                            :cover="true"
                            class="u-height-100 u-width-100"
                            @error-load="onImageErrorLoadDo( imagesToDisplay[3].src )" />
                        <template slot="overlay">
                            <p class="gst-remaining-images-number d-flex u-width-100 u-height-100">
                                {{ remainingImages }}
                            </p>
                        </template>
                    </ContainerWithOverlay>
                </div>
            </div>
        </div>

        <div v-if="layout5" class="gst-image-masonry-variant-1_layout_five">
            <BaseImage
                :src="imagesToDisplay[0].src"
                :fallback-src="imagesToDisplay[0].fallbackSrc"
                :cover="true"
                aspect-ratio="1"
                class="gst-image-masonry-variant-1__image"
                @error-load="onImageErrorLoadDo( imagesToDisplay[0].src )" />
            <div class="gst-image-masonry-variant-1_layout_five__right">
                <div v-for="(image, index) in imagesToDisplayWithoutFirst" :key="index" class="gst-image-masonry-variant-1__image">
                    <ContainerWithOverlay
                        :absolute="true"
                        :overlay="index === imagesToDisplay.length - 2 && remainingImagesNumber > 0">
                        <BaseImage
                            :src="imagesToDisplayWithoutFirst[index].src"
                            :fallback-src="imagesToDisplayWithoutFirst[index].fallbackSrc"
                            class="u-height-100 u-width-100"
                            :cover="true"
                            aspect-ratio="1"
                            @error-load="onImageErrorLoadDo( imagesToDisplay[index].src )" />
                        <template slot="overlay">
                            <p class="gst-remaining-images-number d-flex u-width-100 u-height-100">
                                {{ remainingImages }}
                            </p>
                        </template>
                    </ContainerWithOverlay>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ContainerWithOverlay from '@core/shared/components/overlays/ContainerWithOverlay.vue';
    import { getUnique as getUniqueArray } from '@core/utils/arrayUtils';
    import BaseImage from '@tenants/ticketmaster/app/components/BaseImage';
    import ImageGalleryModal from '@tenants/ticketmaster/app/components/images/ImageGalleryModal.vue';
    import { getImageByWidth } from '@tenants/ticketmaster/app/utils/imageModelUtils';
    import { IMAGE_SIZE } from '@tenants/ticketmaster/app/utils/constants/imageData';


    export default {
        name: 'ImageMasonryVariant1',
        i18nOptions: {
            namespaces: 'shared',
            keyPrefix: 'components.images.imageMasonryVariant1',
        },
        components: {
            BaseImage,
            ContainerWithOverlay
        },
        props: {
            images: {
                type: Array,
                required: true,
            },
            canOpenFullScreen: {
                type: Boolean,
                default: true
            },
            imagesOnDesktop: {
                type: Number,
                default: 5
            },
            imagesOnMobile: {
                type: Number,
                default: 1
            }
        },
        data( ) {
            return {
                errorLoadImages: []
            };
        },
        computed: {
            imagesBaseOnViewportSize ( ) {
                return this.images
                    .map( image => {
                        return {
                            src: getImageByWidth( this.$vuetify.breakpoint.mdAndUp ? IMAGE_SIZE.LARGE : IMAGE_SIZE.MEDIUM, image ),
                            fallbackSrc: getImageByWidth( IMAGE_SIZE.ORIGINAL, image )
                        };
                    } );
            },
            imagesToDisplay( ) {
                return this.imagesBaseOnViewportSize
                    .filter( item => this.errorLoadImages.indexOf( item.src ) === -1 )
                    .slice( 0, this.$vuetify.breakpoint.mdAndUp ? this.imagesOnDesktop : this.imagesOnMobile );
            },
            imagesToDisplayWithoutFirst( ) {
                return [ ...this.imagesToDisplay ].slice( 1 );
            },
            remainingImagesNumber ( ) {
                return this.galleryImages.length - this.imagesToDisplay.length;
            },
            remainingImages( ) {
                return this.$t( 'remainingImages', { remainingImagesNumber: this.remainingImagesNumber } );
            },
            galleryImages ( ) {
                return this.images.map( ( image ) => getImageByWidth( IMAGE_SIZE.ORIGINAL, image ) );
            },
            layout0( ) {
                return  !this.imagesToDisplay.length;
            },
            layout1( ) {
                return  this.imagesToDisplay.length === 1;
            },
            layout2( ) {
                return  this.imagesToDisplay.length === 2;
            },
            layout3( ) {
                return  this.imagesToDisplay.length === 3;
            },
            layout4( ) {
                return  this.imagesToDisplay.length === 4;
            },
            layout5( ) {
                return  this.imagesToDisplay.length === 5;
            }
        },
        methods: {
            openFullScreenGallery ( ) {
                this.canOpenFullScreen && this.$modal.show(
                    ImageGalleryModal,
                    { items: this.images },
                    {
                        'no-click-animation': true,
                        scrollable: false,
                        fullscreen: true,
                        persistent: false,
                        'hide-overlay': false,
                        'content-class': 'gst-v-dialog-gallery',
                    }
                );
            },
            onImageErrorLoadDo( item ) {
                this.errorLoadImages = getUniqueArray( [
                    ...this.errorLoadImages,
                    item
                ] );
            }
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-image-masonry-variant-1 {
    display: flex;
    position: relative;
    padding: theme-spacing( 0, 4 );
    min-height: 380px;

    .v-image,
    .gst-container-with-overlay {
        border-radius: border-radius( 's' );
    }

    .gst-image-masonry-variant-1__image {
        display: flex;
        position: relative;

        .gst-remaining-images-number {
            font-size: font-size( 'l' ) !important;
            font-weight: font-weight( 'large' ) !important;
        }
    }

    > div {
        display: flex;
        width: 100%;
        max-height: 100%;
    }

    .gst-image-masonry-variant-1_layout_one {
        display: flex;
        position: relative;
        justify-content: center;

        .gst-remaining-images-number {
            font-size: font-size( 'l' ) !important;
            font-weight: font-weight( 'large' ) !important;
        }

        .v-image {
            display: flex;
            position: absolute;
            width: 100%;
            transform: translateY( -25% );
            align-items: center;
        }
    }

    .gst-image-masonry-variant-1_layout_two {
        .gst-image-masonry-variant-1__image {
            display: flex;
            position: relative;
            width: calc( 50% - 2px );

            &:first-child {
                margin-right: 4px;
            }
        }
    }

    .gst-image-masonry-variant-1_layout_three {
        display: inline-flex;
        width: 100%;

        > .gst-image-masonry-variant-1__image {
            height: auto;
            width: calc( 66.66% - 2px );
            margin-right: 4px;
        }

        .gst-image-masonry-variant-1_layout_three__right {
            display: flex;
            height: auto;
            width: calc( 33.33% - 2px );
            flex-direction: column;

            .gst-image-masonry-variant-1__image {
                display: flex;
                position: relative !important;
                height: calc( 50% - 2px );
                width: 100%;

                &:first-child {
                    margin-bottom: 4px;
                }
            }
        }
    }

    .gst-image-masonry-variant-1_layout_four {
        display: flex;
        width: 100%;

        > .gst-image-masonry-variant-1__image {
            height: auto;
            width: calc( 60% - 2px );
            margin-right: 4px;
        }

        .gst-image-masonry-variant-1_layout_four__right {
            display: flex;
            width: calc( 40% - 2px );
            flex-direction: column;
        }

        .gst-image-masonry-variant-1_layout_four__top-right {
            display: flex;
            height: calc( 50% - 2px );
            margin-bottom: 4px;

            .gst-image-masonry-variant-1__image {
                height: 100%;
                width: calc( 50% - 2px );

                &:first-child {
                    margin-right: 4px;
                }
            }
        }

        .gst-image-masonry-variant-1_layout_four__bottom-right {
            display: flex;
            position: relative;

            .gst-remaining-images-number {
                font-size: font-size( 'l' ) !important;
                font-weight: font-weight( 'large' ) !important;
                font-family: "Averta Std", sans-serif;
            }

            .gst-image-masonry-variant-1__image {
                height: 100%;
                width: 100%;
            }
        }
    }

    .gst-image-masonry-variant-1_layout_five {
        display: flex;
        width: 100%;

        > .gst-image-masonry-variant-1__image {
            height: auto;
            width: auto;
            margin-right: 4px;

            &.fallback-image {
                width: 50%;
            }
        }

        .gst-image-masonry-variant-1_layout_five__right {
            display: flex;
            width: calc( 50% - 4px );
            flex-wrap: wrap;

            .gst-image-masonry-variant-1__image {
                display: flex;
                position: relative;
                height: calc( 50% - 2px );
                width: calc( 50% - 2px );

                &:nth-child(2n+1) {
                    margin-right: 4px;
                }

                &:first-child,
                &:nth-child(2) {
                    margin-bottom: 4px;
                }
            }
        }
    }
}

@include mobile-only {
    .gst-image-masonry-variant-1 {
        padding: theme-spacing( 1, 1, 3 ) !important;
        min-height: 350px;

        .gst-image-masonry-variant-1_layout_one {
            display: flex;
            position: relative;
            height: 100%;
            justify-content: center;

            .v-image {
                display: flex;
                position: absolute;
                height: 100%;
                width: 100%;
                transform: translateY( 0% );
                align-items: center;
            }
        }

        .gst-image-masonry-variant-1_layout_four {
            flex-direction: column;

            > .gst-image-masonry-variant-1__image {
                height: 50%;
                width: 100%;
            }

            .gst-image-masonry-variant-1_layout_four__right {
                height: 50%;
                width: 100%;
                margin-top: 4px;
                flex-direction: row;

                .gst-image-masonry-variant-1_layout_four__top-right {
                    height: 100%;
                    width: calc( 66.66% - 2px );
                    flex-direction: row;

                    .gst-image-masonry-variant-1__image {
                        width: calc( 50% - 2px );
                    }
                }

                .gst-image-masonry-variant-1_layout_four__bottom-right {
                    height: 100%;
                    width: calc( 33.33% - 2px );
                    margin-left: 4px;
                }
            }
        }
    }
}
</style>
